import {
  Box,
  Flex,
  VStack,
  Text,
  Image,
  Heading,
  Button,
} from '@chakra-ui/react';
import React from 'react';

export interface IntroCardProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  icon?: 'trySatellite' | 'wavingHands' | 'brofist';
  customPaddingPropertiesForMobile?: string;
  iconContainerMarginBottom?: string;
  justifyChildrenPosition?: string;
  showNoIconsAndTitle?: boolean;
}

const IntroCard: React.FunctionComponent<IntroCardProps> = ({
  children,
  subtitle,
  title,
  icon,
  customPaddingPropertiesForMobile,
  iconContainerMarginBottom,
  justifyChildrenPosition = 'flex-end',
  showNoIconsAndTitle,
}) => {
  const icons: { [key: string]: any } = {
    ['trySatellite']: (
      <>
        <Box
          position={'absolute'}
          width={'108px'}
          height={'119px'}
          left="-60px"
          top="160px"
          zIndex={1}
        >
          <Image src="/images/spaceBall.png?v=1" />
        </Box>
        <Box
          position={'absolute'}
          width={'108px'}
          height={'119px'}
          right="-30px"
          top={{md: "16px", base: "5px"}}
          zIndex={1}
        >
          <Image src="/images/radio.png" />
        </Box>
      </>
    ),
    ['wavingHands']: (
      <>
        {' '}
        <Box
          position={'absolute'}
          width={{ base: '48px', md: '90px' }}
          height={'53px'}
          left={{ base: '20px', md: '-15px' }}
          top={{ base: '140px', md: '50px' }}
          zIndex={1}
        >
          <Image src="/images/wavingHand.png" />
        </Box>
        <Box
          position={'absolute'}
          width={{ base: '61px', md: '90px' }}
          height={{ base: '68px', md: '100px' }}
          transform={{ base: 'rotate(0deg)', md: 'rotate(45deg)' }}
          right={{ base: '30px', md: '-20px' }}
          top="150px"
          zIndex={1}
        >
          <Image src="/images/detective.png" />
        </Box>
      </>
    ),
    ['brofist']: <></>,
  };
  return (
    <VStack
      bg={{ base: 'transparent', md: 'white' }} // Make background color transparent on tablet view
      boxShadow={{ base: 'none', md: 'lg' }}
      h="auto" // Changed from a fixed height to auto
      borderRadius="xl"
      p={{
        base: customPaddingPropertiesForMobile || '40px 30px 0px 30px',
        md: '50px 70px 50px 70px',
      }}
      align={{ base: 'stretch', md: 'center' }} // Aligns children to stretch to the full width
      gap="0px"
      maxW={{ base: '100%', md: '490px' }}
      height={{ md: '664px' }}
      position={{ base: 'relative' }}
      overflow={{ md: 'hidden' }}
    >
      <Flex
        flexDir={'column'}
        height={{ md: '100%' }}
        justify={{ md: 'space-between' }}
      >
        {!showNoIconsAndTitle && (
          <Flex
            width={{ base: '100%', md: '100%' }}
            maxWidth={{ md: '310px' }}
            height={{ base: '200px', md: '100%' }}
            maxH={{ md: '176px' }}
            flexDirection={'column'}
            paddingLeft={{ md: '10px' }}
            justifyContent={{ base: 'center', md: 'flex-start' }}
            align={{ base: 'center' }}
            transform={'rotate(-19.731deg)'}
            position={{ base: 'relative' }}
            left={{ base: '25px', md: '0px' }}
            paddingRight={{ base: '40px', md: '0px' }}
          >
            <Box
              sx={{
                color: '#703B7D',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '111.35%',
                paddingRight: '30px',
              }}
              fontSize={{ base: '52px', md: '58px' }}
            >
              {title}
            </Box>{' '}
            <Box
              sx={{
                color: '#000',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '85%',
              }}
              fontSize={{ base: '70px', md: '76px' }}
            >
              {subtitle}
            </Box>
          </Flex>
        )}
        <Flex
          flexDir={'column'}
          height={'100%'}
          justify={justifyChildrenPosition}
        >
          {children}
        </Flex>
      </Flex>
      {!showNoIconsAndTitle && (
        <Box mb={{ base: iconContainerMarginBottom || '0px', md: '0px' }}>
          {icons[icon as string]}
        </Box>
      )}
    </VStack>
  );
};

export default IntroCard;
