import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { ReactComponent as InstagramIcon } from '../../assets/img/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/img/icons/twitter.svg';
import { ReactComponent as SmileyFace } from '../../assets/img/icons/smileyFace.svg';
const Footer = () => {
  return (
    <Flex
      bg="gray.800"
      color="white"
      p={'16px 24px'}
      position={'absolute'}
      bottom="0px"
      background="transparent"
      width={'100%'}
      alignItems={'center'}
    >
      <Flex width="33.3%"></Flex>
      <Flex width="33.3%" justifyContent={'center'}>
        {' '}
        <Text
          color="black"
          fontSize="16px"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="111.35%"
          onClick={() => (window.location.href = 'https://popcorn.space')}
          cursor="pointer"
        >
          Built by 🍿 Popcorn
        </Text>
      </Flex>
      <Flex align="center" width="33.3%" justifyContent={'flex-end'} gap="16px">
        <Link href="https://www.youtube.com/watch?v=half4tsiKTA&ab_channel=DeepSpaceHouse">
          <Flex
            height={'32px'}
            width={'32px'}
            background={'white'}
            borderRadius={'10px'}
            justify={'center'}
            align={'center'}
            _hover={{ background: 'gray.200' }}
          >
            <Icon as={SmileyFace} />
          </Flex>
        </Link>
        <Link href="https://twitter.com/Satellite_Plan">
          <Flex
            height={'32px'}
            width={'32px'}
            background={'white'}
            borderRadius={'10px'}
            justify={'center'}
            align={'center'}
            _hover={{ background: 'gray.200' }}
          >
            <Icon as={InstagramIcon} />
          </Flex>
        </Link>
        <Link href="https://instagram.com/satelliteplan">
          <Flex
            height={'32px'}
            width={'32px'}
            background={'white'}
            borderRadius={'10px'}
            justify={'center'}
            align={'center'}
            _hover={{ background: 'gray.200' }}
          >
            <Icon as={TwitterIcon} />
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Footer;
