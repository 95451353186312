import {callGet, callPost} from "../../lib/api-private";
import {GetUserResponse} from "../../generated-client/model/get-user-response";
import {GetRefereesResponse} from "../../generated-client/model/get-referees-response";
import {SetReferrerRequest} from "../../generated-client/model/set-referrer-request";
import {VerifyReferralCodeRequest} from "../../generated-client/model/verify-referral-code-request";
import {VerifyReferralCodeResponse} from "../../generated-client/model/verify-referral-code-response";
import {SendPopcornRequest} from "../../generated-client/model/send-popcorn-request";

export const getUser = async () : Promise<GetUserResponse> => {
  const data = await callGet("/user");
  return data;
};

export const getReferees = async () : Promise<GetRefereesResponse> => {
  const data = await callGet("/user/referees");
  return data;
};

export const setReferrer = async (data: SetReferrerRequest) : Promise<any> => {
  const response = await callPost("/user/referrer", data);
  return response;
};

export const sendPopcorn = async (data: SendPopcornRequest) : Promise<void> => {
  await callPost("/user/send-popcorn", data);
};

export const copyReferral = async () : Promise<void> => {
  await callPost("/user/copy-referral-code", {});
};


export const verifyReferralCode = async (data: VerifyReferralCodeRequest) : Promise<VerifyReferralCodeResponse> => {
  const response = await callPost("/user/verify-referral-code", data);
  return response;
};
