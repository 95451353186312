import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  VStack,
  useBreakpointValue,
  useMediaQuery,
  Spacer,
  Image,
} from '@chakra-ui/react';
import PhoneInput, { Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.css';
import './../../../assets/css/App.css';
import { sendOtp, verifyCode } from '../../../services/auth';
import Cookies from 'universal-cookie';
import { getUser, verifyReferralCode } from '../../../services/user';
import { ReactComponent as HomeScreenIcon } from '../../../assets/img/icons/homeScreen.svg';
import { ReactComponent as SignUpIcon } from '../../../assets/img/icons/signUp.svg';

const cookies = new Cookies();

const getCountryCodeFromBrowserLanguage = () => {
  const browserLanguage = navigator.language.toUpperCase();
  return browserLanguage.split('-')[1] || browserLanguage;
};

function SignUpIntro() {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const backgroundColor = useBreakpointValue({
    base: 'white',
    md: '#E6E6E6',
  });

  return (
    <Flex
      w="100vw"
      h="100vh"
      flexDirection="column"
      backgroundImage={"url('/images/background.png')"}
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      alignItems={{ base: 'center', md: 'center' }}
      position={{ base: 'relative', md: 'relative' }}
      overflowX="hidden"
    >
      <VStack
        bg={{ base: 'transparent', md: 'white' }} // Make background color transparent on tablet view
        boxShadow={{ base: 'none', md: 'lg' }}
        h="auto" // Changed from a fixed height to auto
        borderRadius="xl"
        p={{ base: '40px 0px 0px 0px', md: '50px 40px 27px 40px' }}
        align="stretch" // Aligns children to stretch to the full width
        gap="0px"
        width={{ base: '100%', md: '490px' }}
        height={{ md: '650px' }}
        position={{ base: 'relative' }}
        overflow={{ md: 'hidden' }}
      >
        <>
          <HomeScreenIcon
            width="100%"
            style={{
              display: 'block',
              zIndex: 2,
            }}
          />
          <Box
            position={'absolute'}
            width={'108px'}
            height={'119px'}
            left="-60px"
            top="170px"
            zIndex={1}
          >
            <Image src="/images/spaceBall.png?v=1" />
          </Box>
          <Box
            position={'absolute'}
            width={'108px'}
            height={'119px'}
            right="-30px"
            top="-20px"
            zIndex={1}
          >
            <Image src="/images/radio.png" />
          </Box>
        </>
        <Box
          bg={{
            base: '#E6CEE5',
            md: 'white',
          }}
          h="100vh"
          borderRadius={{ base: '40px 40px 0px 0px' }}
          p={{ base: '24px 30px 40px 30px' }}
          width={{ base: '100vw', md: '100%' }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            width="100%"
            style={{
              color: '#000',
              fontFamily: 'Radix',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
            }}
          >
            <Text mb="5px" textAlign="center">
              Democratizing Connectivity
            </Text>
            <br />
            <Text mb="5px" textAlign="center">
              We aim to ensure everyone, everywhere, remains connected without
              the fear of charges or disconnection.
            </Text>
            <br />
            <Text mb={{ base: '24px', md: '20px' }} textAlign="center">
              Our pioneering global plan symbolizes a revolution is making every
              corner of the world accessible.
            </Text>
            <Button
              w={{ base: 'full', md: '80%' }}
              minH="52px"
              bg="#232124"
              color="white"
              onClick={() => navigate('/auth/sign-up')}
              _hover={{
                bg: 'gray.700',
              }}
              borderRadius={'8px'}
              mb={{ base: '24px', md: '15px' }}
              mt={{ md: '10px' }}
            >
              Next
            </Button>
          </Flex>
        </Box>
      </VStack>
    </Flex>
  );
}

export default SignUpIntro;
