import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes';

// Chakra imports
import { Box, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';
import SignIn from '../../views/auth/signIn';
import Footer from '../../components/footer/Footer';

// Custom Chakra theme
export default function Auth() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === '/auth') {
        return (
          <Route
            path={route.path}
            element={React.createElement(route.component)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const authBg = useColorModeValue('white', 'navy.900');
  document.documentElement.dir = 'ltr';

  const value = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
  });

  const isBiggerThenTablet = value === 'tablet';

  return (
    <Box position={'relative'}>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" minH="100vh">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<SignIn />} />
            </Routes>
          </Box>
          {isBiggerThenTablet && <Footer />}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
