import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  VStack,
  useBreakpointValue,
  useMediaQuery,
  Spacer,
  Image,
} from '@chakra-ui/react';
import PhoneInput, { Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.css';
import './../../../assets/css/App.css';
import { sendOtp, verifyCode } from '../../../services/auth';
import Cookies from 'universal-cookie';
import { getUser, verifyReferralCode } from '../../../services/user';
import { ReactComponent as HomeScreenIcon } from '../../../assets/img/icons/homeScreen-smaller.svg';
import { ReactComponent as SignUpIcon } from '../../../assets/img/icons/signUp.svg';
import IntroCard from '../../../components/introCard/IntroCard';

const cookies = new Cookies();

const getCountryCodeFromBrowserLanguage = () => {
  const browserLanguage = navigator.language.toUpperCase();
  return browserLanguage.split('-')[1] || browserLanguage;
};

function SignUp() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [otpRequestSent, setOtpRequestSent] = useState(false);
  const [code, setCode] = useState(new Array(6).fill(''));
  const cookies = new Cookies();
  const [referrerCode, setReferrerCode] = useState(
    cookies.get('referralCode') || '',
  );
  const [isReferralValid, setReferralValid] = useState(false);
  const [referralCodeError, setReferralCodeError] = useState('');
  const [formError, setFormError] = useState('');
  const [codeError, setCodeError] = useState('');
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const handleFocus = () => {
    if (isMobile) {
      setKeyboardVisible(true);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  };
  //
  // Function to show image and description
  const handleBlur = () => {
    if (isMobile) {
      setKeyboardVisible(false);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  const handleChange = (element: any, index: number) => {
    let newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }

    if (newCode.join('').length === 6) {
      handleSubmit(newCode);
    }
  };

  const handleKeyDown = (element: any, index: number) => {
    if (
      (element.key === 'Backspace' || element.key === 'Delete') &&
      !code[index]
    ) {
      if (index > 0) {
        element.target.previousSibling.focus();
      }
    }
    setCodeError('');
  };

  const verifyRefCode = async () => {
    setIsLoading(true);
    console.log(referrerCode);
    try {
      const response = await verifyReferralCode({
        referralCode: referrerCode.toString(),
      });

      if (response.isValid) {
        setReferralValid(true);
      } else {
        setReferralCodeError('Wrong referral code. Please try again!');
      }
    } catch (e) {
      setReferralCodeError('Wrong referral code. Please try again!');
    }
    setIsLoading(false);
  };

  const handleSubmit = async (code: string[]) => {
    setIsLoading(true);
    try {
      const response = await verifyCode({
        phoneNumber: phoneNumber,
        email: email,
        fullName: fullName,
        code: code.join(''),
        referralCode: referrerCode,
        isMobile: isMobile,
      });

      // If the response contains the bearerToken
      if (response.accessToken) {
        // Store the bearerToken in a cookie that expires in 1 hour
        cookies.set('accessToken', response.accessToken, {
          path: '/',
          maxAge: 3600000,
        });
      }

      setIsLoading(false);

      navigate('/dashboard/default');
    } catch (e) {
      setCode(['', '', '', '', '', '']);
      inputRef.current.focus();
      setCodeError('Invalid code. Please wait and try again.');
    }
    setIsLoading(false);
  };

  const handleLogin = async () => {
    const regexEmail = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const isEmailValid = regexEmail.test(email);
    const isFullNameValid = fullName.length > 1;

    if (!email || !isEmailValid) {
      setFormError('Invalid email. Please check and try again.');
      return;
    }
    if (!fullName || !isFullNameValid) {
      setFormError('Invalid full name. Please check and try again.');
      return;
    }
    if (!phoneNumber) {
      setFormError('Invalid phone number. Please check and try again.');
      return;
    }

    try {
      setIsLoading(true);
      await sendOtp({
        phoneNumber: phoneNumber,
      });
      setOtpRequestSent(true);
    } catch (e) {
      setFormError('Invalid phone number. Please check and try again.');
    }
    setIsLoading(false);
  };

  const backgroundColor = useBreakpointValue({
    base: 'white',
    md: '#E6E6E6',
  });

  const value = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
  });

  const isBiggerThenTablet = value === 'tablet';

  return (
    <Flex
      w="100vw"
      h="100vh"
      flexDirection="column"
      backgroundImage={
        !otpRequestSent || !isMobile ? "url('/images/background.png')" : null
      }
      bg={
        !otpRequestSent || !isMobile ? null : { base: '#E6CEE5', md: 'white' }
      }
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      alignItems={{ base: 'center', md: 'center' }}
      position={{ base: 'relative', md: 'relative' }}
      overflowX="hidden"
    >
      <IntroCard
        title="Try"
        subtitle="Satellite"
        icon={isReferralValid ? 'wavingHands' : 'trySatellite'}
        customPaddingPropertiesForMobile={'40px 0px 0px 0px'}
        iconContainerMarginBottom={isReferralValid ? '40px' : '0px'}
        justifyChildrenPosition={
          otpRequestSent && isReferralValid && !isBiggerThenTablet
            ? 'center'
            : 'flex-end'
        }
        showNoIconsAndTitle={
          otpRequestSent && isReferralValid && !isBiggerThenTablet
        }
      >
        <Box
          bg={{
            base: isReferralValid ? 'transparent' : '#E6CEE5',
            md: 'white',
          }}
          h={{
            base: '100vh',
            md: otpRequestSent && isReferralValid ? '100%' : 'unset',
          }}
          borderRadius={{ base: '40px 40px 0px 0px' }}
          p={{ base: '24px 30px 40px 30px', md: '0px' }}
          width={{ base: '100vw', md: '100%' }}
        >
          {!isReferralValid && (
            <Flex
              flexDirection="column"
              alignItems="center"
              width="100%"
              style={{
                color: '#000',
                fontFamily: 'Radix',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 400,
              }}
            >
              <Text mb="5px" textAlign="center">
                One powerful plan to call, text and surf globally. No hidden
                costs.
              </Text>
              <br />
              <Text mb="5px" textAlign="center">
                In partnership with T-Mobile & Starlink.
              </Text>
              <br />
              <Text mb={{ base: '24px', md: '20px' }} textAlign="center">
                Coming 2024.
              </Text>
              <Flex justifyContent="center" mb="7px" width="100%">
                <FormControl
                  isInvalid={!!referralCodeError}
                  w={{ base: '100%', md: '100%' }}
                >
                  <Input
                    placeholder="Enter code"
                    value={referrerCode}
                    onChange={(e) => setReferrerCode(e.target.value)}
                    borderRadius="8px"
                    // borderColor="#D00E17"
                    h="40px"
                    w="100%"
                    minH="52px"
                    // onFocus={handleFocus}
                    onBlur={handleBlur}
                    backgroundColor={{ base: 'white', md: '#E6E6E6' }}
                    _placeholder={{ color: 'black' }}
                  />
                  <FormErrorMessage>{referralCodeError}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Button
                w={{ base: 'full', md: '100%' }}
                minH="52px"
                bg="#232124"
                color="white"
                isLoading={isLoading}
                onClick={verifyRefCode}
                _hover={{
                  bg: 'gray.700',
                }}
                borderRadius={'8px'}
                mb={{ base: '24px', md: '15px' }}
                mt={{ md: '10px' }}
              >
                Join waitlist
              </Button>
            </Flex>
          )}
          {!otpRequestSent && isReferralValid && (
            <Flex flexDirection="column" alignItems="center" width="100%">
              <Text
                mb={{ base: '24px', md: '30px' }}
                textAlign="center"
                style={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '111.35%',
                }}
                fontSize={{ base: '18px', md: '20px' }}
              >
                To begin, you'll need to tell us a little about you.
              </Text>
              <Input
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                mb="10px"
                borderRadius="8px"
                // borderColor="#D00E17"
                h="52px"
                w="100%"
                minH="40px"
                background={{ base: 'white', md: '#E6E6E6' }}
                opacity={0.6}
                _placeholder={{
                  color: '#555',
                  fontFamily: 'Radix',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '111.35%',
                }}
              />
              <Input
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mb="10px"
                borderRadius="8px"
                type="email"
                // borderColor="#D00E17"
                h="52px"
                w="100%"
                minH="40px"
                background={{ base: 'white', md: '#E6E6E6' }}
                opacity={0.6}
                _placeholder={{
                  color: '#555',
                  fontFamily: 'Radix',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '111.35%',
                }}
              />
              <FormControl isInvalid={!!formError}>
                <PhoneInput
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  defaultCountry={
                    getCountryCodeFromBrowserLanguage() as Country
                  }
                  onChange={setPhoneNumber}
                  mb="5px"
                  borderRadius="0"
                  international
                  onBlur={handleBlur}
                  displayInitialValueAsLocalNumber
                  style={{
                    borderRadius: '8px',
                    height: '52px',
                    width: '100%',
                    outline: 'none',
                    padding: '10px 8px 10px 10px',
                    marginBottom: '5px',
                    backgroundColor,
                    opacity: '0.6',
                  }}
                  className={'input-phone-number'}
                />
              </FormControl>
              <Button
                w="full"
                mt="10px"
                minH="52px"
                isLoading={isLoading}
                bg="#232124"
                color="white"
                onClick={handleLogin}
                _hover={{
                  bg: 'gray.700',
                }}
                borderRadius={'8px'}
              >
                Next
              </Button>
              <Text pt="5px" color="red">
                {formError}
              </Text>
            </Flex>
          )}
          {otpRequestSent && isReferralValid && (
            <Flex
              flexDirection="column"
              alignItems="center"
              width="100%"
              h={{ base: 'calc(var(--vh, 1vh) * 60)', md: '100%' }}
            >
              {!keyboardVisible && (<><Spacer /><Box pt='50px'></Box></>)}{' '}
              {/* This pushes the content to the center */}
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Text fontSize="2xl" mb="5px" textAlign="center">
                  <b>Verify the code!</b>
                </Text>
                <Text textAlign="center" pb="20px">
                  We just sent a code to your phone. Check your SMS!
                </Text>
                <Flex justifyContent="center" mb="20px">
                  <FormControl>
                    {code.map((digit, index) => (
                      <Input
                        key={index}
                        ref={index === 0 ? inputRef : null}
                        type="number"
                        inputMode="numeric"
                        pattern="\d*"
                        pl="1px"
                        pr="1px"
                        onFocus={() => {
                          if (index === 0) {
                            handleFocus();
                          } else {
                            if(code.join('').length === 0 && index !== 1) {
                              inputRef.current.focus(); // Redirect focus to the first empty input
                            }
                          }
                        }}
                        onBlur={() => (index === 5 ? handleBlur() : undefined)}
                        value={digit}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        placeholder="—"
                        textAlign="center"
                        fontSize="2xl" // Makes the text larger
                        maxW="45px"
                        maxH="50px" // Adjust height accordingly
                        border="none"
                        marginRight="0px"
                        _focus={{
                          outline: 'none',
                          boxShadow: 'none',
                          caretColor: 'transparent', // This will make the caret/cursor invisible on focus
                        }}
                        _placeholder={{
                          color: 'black', // Dark black color
                        }}
                        autoComplete="one-time-code"
                        // autoFocus={index === 0}
                      />
                    ))}
                    <Text color="red">{codeError}</Text>
                  </FormControl>
                </Flex>
              </Flex>
              {!keyboardVisible && <Spacer />}
              <Button
                w="full"
                minH="52px"
                bg={code.join('').length === 6 ? 'black' : 'gray.400'} // Change to gray when code length is not 6
                color="white"
                isLoading={isLoading}
                onClick={() => handleSubmit(code)}
                _hover={{
                  bg: code.join('').length === 6 ? 'gray.700' : 'gray.400', // Keep grey on hover if disabled
                }}
                disabled={code.join('').length !== 6} // Disable the button if code length is not 6
              >
                Let's go!
              </Button>
            </Flex>
          )}
        </Box>
      </IntroCard>
    </Flex>
  );
}

export default SignUp;
