import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  FormErrorMessage,
  FormControl,
  VStack,
  Spacer,
  useBreakpointValue,
  useMediaQuery,
  Image,
} from '@chakra-ui/react';
import PhoneInput, { Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.css';
import './../../../assets/css/App.css';
import { sendOtp, verifyCode } from '../../../services/auth';
import Cookies from 'universal-cookie';
import { getUser } from '../../../services/user';
import { ReactComponent as HomeScreenIcon } from '../../../assets/img/icons/homeScreen.svg';
import { ReactComponent as SignInIcon } from '../../../assets/img/icons/signIn.svg';

const cookies = new Cookies();

const getCountryCodeFromBrowserLanguage = () => {
  const browserLanguage = navigator.language.toUpperCase();
  return browserLanguage.split('-')[1] || browserLanguage;
};

function SignIn() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpRequestSent, setOtpRequestSent] = useState(false);
  const [code, setCode] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const [codeError, setCodeError] = useState('');
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const value = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
  });
  const isBiggerThenTablet = value === 'tablet';

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = 'hidden';

    window.scrollTo(0, 0);

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  // Function to hide image and description
  const handleFocus = () => {
    if (isMobile) {
      setKeyboardVisible(true);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  // Function to show image and description
  const handleBlur = () => {
    if (isMobile) {
      setKeyboardVisible(false);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  const handleChange = (element: any, index: number) => {
    let newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }

    if (newCode.join('').length === 6) {
      handleSubmit(newCode);
    }
  };

  const handleKeyDown = (element: any, index: number) => {
    if (
      (element.key === 'Backspace' || element.key === 'Delete') &&
      !code[index]
    ) {
      if (index > 0) {
        element.target.previousSibling.focus();
      }
    }
    setCodeError('');
  };

  const handleSubmit = async (code: string[]) => {

    setIsLoading(true);
    try {
      const response = await verifyCode({
        phoneNumber: phoneNumber,
        code: code.join(''),
        isMobile: isMobile,
      });

      // If the response contains the bearerToken
      if (response.accessToken) {
        // Store the bearerToken in a cookie that expires in 1 hour
        cookies.set('accessToken', response.accessToken, {
          path: '/',
          maxAge: 3600000,
        });
      }

      setIsLoading(false);

      navigate('/dashboard/default');
    } catch (e) {
      setCode(['', '', '', '', '', '']);
      inputRef.current.focus();
      setCodeError('Invalid code. Please wait and try again.');
    }
    setIsLoading(false);
  };

  const handleLogin = async () => {
    setError('');
    setIsLoading(true);
    try {
      await sendOtp({
        phoneNumber: phoneNumber,
      });
      setOtpRequestSent(true);
      setIsLoading(false);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 200);
    } catch (e) {
      setError('Invalid phone number. Please check and try again.');
      setIsLoading(false);
    }
  };

  function setViewportHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  // Set the initial value
  setViewportHeight();

  // Update on resize or orientation change
  window.addEventListener('resize', setViewportHeight);
  window.addEventListener('orientationchange', setViewportHeight);

  const backgroundColor = useBreakpointValue({
    base: 'white',
    md: '#E6E6E6',
  });

  return (
    <Flex
      w="100vw"
      h={{base: "calc(var(--vh, 1vh) * 120)", md: "calc(var(--vh, 1vh) * 100)"}} // Use the CSS variable here
      flexDirection="column"
      backgroundImage={
        !otpRequestSent || !isMobile ? "url('/images/background.png')" : null
      }
      bg={
        !otpRequestSent || !isMobile ? null : { base: '#E6CEE5', md: 'white' }
      }
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      alignItems={{ base: 'center', md: 'center' }}
    >
      <VStack
        bg={{ base: 'transparent', md: 'white' }} // Make background color transparent on tablet view
        boxShadow={{ base: 'none', md: 'lg' }}
        h="auto" // Changed from a fixed height to auto
        borderRadius="xl"
        p={{ base: '90px 30px 0px 30px', md: '90px 30px 0px 30px' }}
        align="stretch" // Aligns children to stretch to the full width
        gap="0px"
        width={{ base: '100%', md: '490px' }}
        height={{ md: '650px' }}
        position={{ base: 'relative' }}
        overflow={{ md: 'hidden' }}
      >
        {!otpRequestSent && !keyboardVisible && (
          <>
            <SignInIcon width="100%" />
            <Box
              position={'absolute'}
              width={{ base: '122px' }}
              height={{ base: '68px' }}
              left={{ base: '110px', md: '140px' }}
              top={{ base: '60px', md: '60px' }}
              zIndex={1}
            >
              <Image src="/images/clashingHands.png" />
            </Box>
          </>
        )}
        <Box minW="250px" minH="200px" p={{ base: '20px', md: '100px 50px 20px 50px' }}  borderRadius="lg">
          {!otpRequestSent ? (
            <Flex
              flexDirection="column"
              justifyContent="center" // Aligns children vertically in the center
              alignItems="center" // Aligns children horizontally in the center
              width="100%"
              h={{ base: 'calc(var(--vh, 1vh) * 55)', md: '100%' }}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Text fontSize="lg" mb="5px" textAlign="center">
                  Enter your phone number to log back into your account.
                </Text>
              </Flex>
              <FormControl isInvalid={!!error}>
                <PhoneInput
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  defaultCountry={
                    getCountryCodeFromBrowserLanguage() as Country
                  }
                  onChange={setPhoneNumber}
                  mb="20px"
                  borderRadius="0"
                  international
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  displayInitialValueAsLocalNumber
                  style={{
                    borderRadius: '8px',
                    fontColor: 'black',
                    height: '52px',
                    width: '100%',
                    outline: 'none',
                    padding: '10px 8px 10px 10px',
                    marginBottom: '20px',
                    backgroundColor,
                    opacity: '0.75',
                  }}
                  className={'input-phone-number'}
                />
                <FormErrorMessage>{error}</FormErrorMessage>
                <Button
                  w="full"
                  mt="20px"
                  minH="52px"
                  bg="black"
                  color="white"
                  isLoading={isLoading}
                  onClick={handleLogin}
                  _hover={{
                    bg: 'gray.700',
                  }}
                >
                  Verify phone
                </Button>
              </FormControl>
            </Flex>
          ) : (
              <Flex
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  h={{ base: 'calc(var(--vh, 1vh) * 80)', md: 'calc(var(--vh, 1vh) * 50)' }}
              >
              {!keyboardVisible && !isBiggerThenTablet && <Spacer />}{' '}
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                  <Text fontSize="2xl" mb="5px" textAlign="center">
                    <b>Verify the code!</b>
                  </Text>
                  <Text textAlign="center" pb="20px">
                    We just sent a code to your phone. Check your SMS!
                  </Text>
                  <Flex justifyContent="center" mb="20px">
                    <FormControl>
                      {code.map((digit, index) => (
                          <Input
                              key={index}
                              ref={index === 0 ? inputRef : null}
                              type="number"
                              inputMode="numeric"
                              pattern="\d*"
                              pl="1px"
                              pr="1px"
                              onFocus={() => {
                                if (index === 0) {
                                  handleFocus();
                                } else {
                                  if(code.join('').length === 0 && index !== 1) {
                                    inputRef.current.focus(); // Redirect focus to the first empty input
                                  }
                                }
                              }}
                              onBlur={() => index === 5 ? handleBlur() : undefined}
                              value={digit}
                              onChange={(e) => handleChange(e.target, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              placeholder="—"
                              textAlign="center"
                              fontSize="2xl" // Makes the text larger
                              maxW="45px"
                              maxH="50px" // Adjust height accordingly
                              border="none"
                              marginRight="0px"
                              _focus={{
                                outline: 'none',
                                boxShadow: 'none',
                                caretColor: 'transparent', // This will make the caret/cursor invisible on focus
                              }}
                              _placeholder={{
                                color: 'black', // Dark black color
                              }}
                              autoComplete="one-time-code"
                              // autoFocus={index === 0}
                          />
                      ))}
                      <Text color='red'>{codeError}</Text>
                    </FormControl>
                  </Flex>
                </Flex>
                {!keyboardVisible && (<Spacer />)}
                <Button
                    w="full"
                    minH="52px"
                    bg={code.join('').length === 6 ? 'black' : 'gray.400'} // Change to gray when code length is not 6
                    color="white"
                    isLoading={isLoading}
                    onClick={() => handleSubmit(code)}
                    _hover={{
                      bg: code.join('').length === 6 ? 'gray.700' : 'gray.400', // Keep grey on hover if disabled
                    }}
                    disabled={code.join('').length !== 6} // Disable the button if code length is not 6
                >
                  Let's goooo!
                </Button>
              </Flex>
          )}
        </Box>
      </VStack>
    </Flex>
  );
}

export default SignIn;
