import { Icon } from '@chakra-ui/react';
import {
	MdPerson,
	MdHome,
} from 'react-icons/md';

// Dashboard Imports
import MainDashboard from 'views/dashboard/default';

// Auth Imports
import SignIn from 'views/auth/signIn';
import SignUp from "./views/auth/signUp";
import Intro from "./views/auth/intro";
import SignUpIntro from "./views/auth/signUpIntro";

const routes = [
	{
		name: 'Home',
		layout: '/dashboard',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Intro',
		layout: '/auth',
		path: '/intro',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: Intro,
		hidden: true
	},
	{
		name: 'Log in',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: SignIn,
		hidden: true,
	},
	{
		name: 'Sign up',
		layout: '/auth',
		path: '/sign-up',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: SignUp,
		hidden: true,
	},
	{
		name: 'Sign up intro',
		layout: '/auth',
		path: '/sign-up-intro',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: SignUpIntro,
		hidden: true,
	},
];

export default routes;
