import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  Progress,
  HStack,
  Divider,
  Link,
  Image,
  useClipboard,
  Tooltip,
  IconButton,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
  Tab,
  Avatar,
  VStack,
  Spinner,
  Center,
  useMediaQuery,
  useBreakpointValue,
} from '@chakra-ui/react';
import PhoneInput, { Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.css';
import './../../../assets/css/App.css';
import { sendOtp, verifyCode } from '../../../services/auth';
import Cookies from 'universal-cookie';
import {copyReferral, getReferees, getUser, sendPopcorn} from '../../../services/user';
import { UserDto } from '../../../generated-client/model/user-dto';
import { RefereeDto } from 'generated-client/model/referee-dto';
import { CopyIcon } from '@chakra-ui/icons';

const cookies = new Cookies();

function MainDashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDto>(null);
  const [referees, setReferees] = useState<any[]>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { hasCopied: hasCopiedCode, onCopy: onCopyCode } = useClipboard(
    user?.referralCode,
  );
  const [tabIndex, setTabIndex] = useState(0); // State to keep track of the active tab index

  // Function to handle tab change
  const handleTabsChange = (index: any) => {
    console.log('AAA: ', index);
    setTabIndex(index);
  };

  const { hasCopied: hasCopiedLink, onCopy: onCopyLink } = useClipboard(
    `Hey! I just got my spot for the world's first cellular and satellite mobile plan.

One phone plan, strong signal worldwide, no hidden costs.

The waitlist is currently invite-only, so you can join by using my link or the code ${user?.referralCode}. https://satellite-plan.com/${user?.referralCode}

It's launching in 2024.`);

  async function fetchData() {
    try {
      setIsLoading(true);
      const data = await getUser();
      const refereesRes = await getReferees();
      console.log(data.user);
      if (!data.user.inviteRequired) {
        navigate('/dashboard/default');
      }
      // referees.referees.push(referees.referees[0]);
      // referees.referees.push(referees.referees[0]);
      const refereesList = refereesRes.referees.map((r) => ({
        name: r.name,
        id: r.id,
        description: 'Joined the party!',
        image: '/astronaut.png',
        showButton: true,
      }));

      // const refereesList: any[] = [];

      if (!refereesList[0]?.name) {
        refereesList.push({
          name: 'Pickle Rick',
          id: undefined,
          description: 'It’s all in your head',
          image: '/pickie_1.png',
          showButton: true,
        });
      }

      if (!refereesList[1]?.name) {
        refereesList.push({
          name: 'Spock',
          id: undefined,
          description: 'Live long and prosper',
          image: '/spock_1.png',
          showButton: true,
        });
      }

      if (!refereesList[2]?.name) {
        refereesList.push({
          name: 'Paris Hilton’s Dog',
          id: undefined,
          description: 'They let everyone in these days',
          image: '/prince_1.png',
          showButton: true,
        });
      }

      setUser(data.user);
      setReferees(refereesList);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
    setIsLoading(false);
  }

  let throttleTimers: any = {};

  function sendPopcornThrottled(to: string) {
    if (to) {
      // Check if a timer already exists for this 'to' value
      if (!throttleTimers[to]) {
        // No timer exists, so call the function and set a timer
        sendPopcorn({ to });

        // Set a timer to prevent further calls for 10 seconds
        throttleTimers[to] = setTimeout(() => {
          // Clear the timer after 10 seconds
          delete throttleTimers[to];
        }, 10000); // 10 seconds
      }
    }
  }

  function addPopcorn(to: string) {
    console.log('POPCORN', to);

    if(to) {
      sendPopcornThrottled(to);
    }

    // Number of popcorns to create per click
    const numberOfPopcorns = Math.random() * 100;

    for (let i = 0; i < numberOfPopcorns; i++) {
      const popcorn = document.createElement('div');
      popcorn.classList.add('popcorn');

      popcorn.style.backgroundImage = "url('popcorn.png')";

      // Randomly position the popcorn in the container
      popcorn.style.left = Math.random() * 100 + '%';
      popcorn.style.top = Math.random() * 100 + '%';

      // Append popcorn to container
      document.querySelector('.popcorn-container').appendChild(popcorn);

      // Animate the popcorn
      const animation = popcorn.animate(
        [
          { transform: 'translateY(0) rotate(0deg)', opacity: 1 },
          {
            transform: `translateY(-${100 + Math.random() * 100}%) rotate(${
              360 * (Math.random() < 0.5 ? 1 : -1)
            }deg)`,
            opacity: 0,
          },
        ],
        {
          duration: 2000 + Math.random() * 1000, // Random duration between 2 to 3 seconds
          easing: 'ease-out',
        },
      );

      animation.onfinish = () => {
        popcorn.remove(); // Remove popcorn after animation
      };
    }
  }

  useEffect(() => {
    let isMounted = true;
    fetchData();

    // Disable scrolling
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = '';
      isMounted = false;
    };
  }, []); // Empty dependency array ensures this runs once on mount

  const value = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
  });

  const handleLogout = () => {
    // Clear the cookie
    cookies.remove('accessToken');

    // Navigate to login page
    navigate('/auth/intro');
  };

  if (isLoading) {
    return (
      <Center w="100vw" h="100vh" background="white" zIndex={10} position={'absolute'}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#E6CEE5"
          size="xl"
        />
      </Center>
    );
  }

  function setViewportHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  // Set the initial value
  setViewportHeight();

  // Update on resize or orientation change
  window.addEventListener('resize', setViewportHeight);
  window.addEventListener('orientationchange', setViewportHeight);

  const isBiggerThenTablet = value === 'tablet';

  return (
    <Flex
      w="100vw"
      className="popcorn-container"
      h="calc(var(--vh, 1vh) * 100)"
      flexDirection="column"
      backgroundImage="url('/images/background.png')"
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent={{ base: 'center', md: 'center' }}
      alignItems={{ base: 'center', md: 'center' }}
    >
      <VStack
        bg={{ base: 'transparent', md: 'white' }} // Make background color transparent on tablet view
        boxShadow={{ base: 'none', md: 'lg' }}
        borderRadius="xl"
        p={{ base: '100px 30px 0px 30px', md: '20px 0px 0px 0px' }}
        align="stretch" // Aligns children to stretch to the full width
        gap="0px"
        width={{ base: '100%', md: '490px' }}
        height={{ md: '650px' }}
        spacing={0}
        position={{ base: 'relative' }}
        overflow={{ md: 'hidden' }}
      >
        <Flex flexDirection="column" alignItems='center' width="100%">
          <Box position={'absolute'} zIndex={0} top={{md: "0px", base: "100px"}}>
            <Image width='80%' pl='20%' src="/images/popcorns.png?v=2" />
          </Box>
          <Text
            fontSize="3xl"
            fontWeight="bold"
            mb="10px"
            zIndex={5}
            textAlign="center"
            color="purple.700"
            transform={'rotate(-12.603deg)'}
          >
            <Text
              color="gray.600"
              style={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Radix',
                fontSize: '64px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '83.3%',
                paddingTop: '45px',
              }}
            >
              <b>{user?.numberInLine}</b>
            </Text>
            <Text
              color="gray.600"
              style={{
                color: '#703B7D',
                textAlign: 'center',
                fontFamily: 'Radix',
                fontSize: '18.353px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '83.3%',
                marginTop: '10px',
              }}
            >
              Position in line
            </Text>
          </Text>

          <Text
            color="gray.600"
            mt={{ base: '50px', md: '40px' }}
            mb="12px"
            p={'0px 40px 0px 40px'}
            style={{
              color: '#232124',
              textAlign: 'center',
              fontFamily: 'Radix',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '111.35%' /* 15.589px */,
              textTransform: 'uppercase',
              zIndex: 1,
            }}
          >
            {tabIndex === 1 && !referees[0].id  ? `THESE ALL LOOK LIKE IMAGINARY FRIENDS.` : 'INVITE YOUR FRIENDS!'} <br/>
            {tabIndex === 1 && !referees[0].id ? `INVITE SOME REAL ONES!` : String.fromCharCode(160)}
          </Text>
          <Flex
            direction="column"
            alignItems="start"
            zIndex={5}
            bg="gray.100"
            p={{ base: '4px 4px 4px 21px' }}
            borderRadius="100px"
            border={{ md: '1px solid #C8B5C3' }}
            mb="35px"
          >
            <Flex alignItems="center">
              <Box bg="white" p={0}>
                <Link
                  color="#232124"
                  href={`https://satellite-plan.com/${user?.referralCode}`}
                  p={0}
                  fontSize={'16px'}
                  mr={'16px'}
                  isExternal
                >
                  satellite-plan.com/{user?.referralCode}
                </Link>
              </Box>
              <Tooltip
                label={hasCopiedLink ? 'Copied!' : 'Copy link'}
                closeOnClick={false}
              >
                <IconButton
                  icon={<CopyIcon />}
                  backgroundColor={'#F1E0EC'}
                  size="sm"
                  onClick={() => {
                    onCopyLink();
                    copyReferral();
                  }}
                  aria-label="Copy referral link"
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Box
            bg={{
              base: '#E6CEE5',
            }}
            minH="200px"
            borderRadius={{ base: '40px 40px 0px 0px', md: '40px' }}
            p={{ base: '24px 30px 40px 30px' }}
            width={{ base: '100vw', md: '100%' }}
            // h="100vh"
          >
            <Tabs defaultIndex={0} onChange={handleTabsChange} isFitted variant="enclosed">
              <TabPanels>
                <TabPanel padding={0}>
                  <Text
                    textAlign="center"
                    fontWeight="medium"
                    pt={{base: '35px', md: '50px'}}
                    style={{
                      color: '#232124',
                      textAlign: 'center',
                      fontFamily: 'Radix',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '111.35%',
                    }}
                  >
                    Invite 3 friends and receive 3 months of free satellite
                    coverage.
                  </Text>
                  <HStack justifyContent="center" spacing="24px">
                    <Text
                        textAlign="center"
                        pt='30px'
                        pb={{base: '0px', md: '18px'}}
                        fontWeight="medium"
                        style={{
                          color: '#232124',
                          textAlign: 'center',
                          fontFamily: 'Radix',
                          fontSize: '30px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '211.35%',
                        }}
                    >
                      🎁 🎁 🎁
                    </Text>
                  </HStack>
                </TabPanel>
                <TabPanel padding={0}>
                  {referees?.map((referee, index) => (
                        <Flex
                          key={index}
                          alignItems="center"
                          borderRadius="md"
                          mb={{ base: '5.8px', md: '4.9px' }}
                          justifyContent="space-between"
                        >
                          <Flex alignItems="center">
                            <Avatar
                              size={{ base: 'md', md: 'md' }}
                              name={referee.name}
                              src={referee.image}
                              mr={'12px'}
                              mt={{md: '8px', base: '2px'}}
                              mb={{md: '8px', base: '2px'}}
                            />
                            <VStack align="start" spacing='1' maxWidth={'250px'}>
                              <Text
                                fontWeight="bold"
                                style={{
                                  color: '#232124',
                                  textAlign: 'center',
                                  fontFamily: 'Radix',
                                  fontSize: '16px',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: '111.35%',
                                }}
                              >
                                {referee.name}
                              </Text>
                              <Text
                                fontSize="sm"
                                style={{
                                  color: '#703B7D',
                                  fontFamily: 'Radix',
                                  fontSize: '16px',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: '111.35%',
                                }}
                              >
                                {referee.description}
                              </Text>
                            </VStack>
                          </Flex>
                          {referee.showButton && (
                            <Button
                              ml={'20px'}
                              variant="outline"
                              onClick={() => addPopcorn(referee?.id)}
                              backgroundColor={'white'}
                              w={'100%'}
                              maxWidth={'79px'}
                              borderRadius={'100px'}
                              fontSize={'14px'}
                            >
                              Send 🍿
                            </Button>
                          )}
                        </Flex>
                      ))}
                </TabPanel>
              </TabPanels>
              <TabList
                mb="1em"
                mt='40px'
                borderBottom='0x'
                borderRadius="full"
                bg="pink.100"
                p="1"
              >
                <Tab
                  borderRadius="full"
                  p="2"
                  _selected={{ bg: 'pink.300', color: 'black' }}
                  sx={{
                    flex: 1,
                    justifyContent: 'center',
                    _hover: { bg: 'white.300' },
                  }}
                >
                  {tabIndex === 0 && '🥳'} Rewards
                </Tab>
                <Tab
                  borderRadius="full"
                  p="2"
                  _selected={{ bg: 'pink.300', color: 'black' }}
                  sx={{
                    flex: 1,
                    justifyContent: 'center',
                    _hover: { bg: 'white.300' },
                  }}
                >
                  {tabIndex === 1 && '🍿'} Friends
                </Tab>
              </TabList>
            </Tabs>
          </Box>
          <br />
          {/* <Button onClick={handleLogout}>Logout</Button> */}
        </Flex>
      </VStack>
    </Flex>
  );
}

export default MainDashboard;
