import React, {useEffect} from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './assets/css/App.css';
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  useNavigate, useParams,
} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import DashboardLayout from './layouts/dashboard';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import 'flag-icon-css/css/flag-icons.css';
import Cookies from 'universal-cookie';
import Fonts from './fonts';

function ProtectedRoute() {
  const navigate = useNavigate();
  const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');


  useEffect(() => {
    console.log('A token:', accessToken);
    // if(accessToken) {
    //   navigate('/dashboard/default');
    //   return;
    // }

    const url = window.location.href
    const parts = url.split('/');
    const potentialRefCode = parts[3];
    if(potentialRefCode?.length === 4) {
      console.log(potentialRefCode);
      cookies.set('referralCode', potentialRefCode, { path: '/' });
    }
    // Redirect to the appropriate screen
    navigate('/auth/intro');
  }, [navigate]);

  return <></>;
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ChakraProvider theme={theme}>
    <Fonts />
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route path="/dashboard/*" element={<DashboardLayout />} />
          <Route path="/" element={<ProtectedRoute />} />
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </ChakraProvider>,
);
