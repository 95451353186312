import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Box,
  Button,
  Text,
  VStack,
  Heading,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.css';
import './../../../assets/css/App.css';

import { ReactComponent as HomeScreenIcon } from './../../../assets/img/icons/homeScreen-smaller.svg';
import { ReactComponent as ArrowRightIcon } from './../../../assets/img/icons/arrowRight.svg';
import Footer from '../../../components/footer/Footer';
import IntroCard from '../../../components/introCard/IntroCard';

function Intro() {
  const navigate = useNavigate();

  const value = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
  });

  const isBiggerThenTablet = value === 'tablet';

  return (
    <Flex
      w="100vw"
      h="100vh"
      flexDirection="column"
      backgroundImage="url('/images/background.png')" // Replace with your desired image URL
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      alignItems={{ base: 'center', md: 'center' }}
      position={{ base: 'relative', md: 'relative' }}
      overflowX="hidden"
    >
      <IntroCard title="Try" subtitle="Satellite" icon="trySatellite">
        <Heading
          as="h1"
          size="xl"
          textAlign="center"
          style={{
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '111.35%',
            marginBottom: '32px',
          }}
          fontSize={{ base: '32px' }}
          marginTop={{ base: '5px', md: '0px' }}
        >
          The phone plan of the future
        </Heading>
        <Text
          fontSize="md"
          textAlign="center"
          style={{
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            marginBottom: '20px',
          }}
        >
          Popcorn is building the world’s first cellular and satellite phone
          plan.
        </Text>
        <Text
          fontSize="lg"
          textAlign="center"
          mb={{ base: '49px', md: '20px' }}
        >
          For the tech curious, adventurers and those in remote areas.
        </Text>
        <Button
          minH="52px"
          bg="#232124"
          color="white"
          borderRadius="8px" // Gives a pill shape to the button
          onClick={() => navigate('/auth/sign-up')}
          _hover={{
            bg: 'gray.700',
          }}
          width={{ base: '100%' }}
          maxW={{ md: '368px' }}
        >
          Tell me more
        </Button>
        <Flex
          flexDirection={'row'}
          gap={'6px'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={'23px'}
        >
          <Text
            textAlign="center"
            fontSize="16px"
            color="black"
            onClick={() => navigate('/auth/sign-in')}
            fontWeight={500}
            cursor="pointer"
          >
            Already in?
          </Text>
          <ArrowRightIcon />
        </Flex>
      </IntroCard>
    </Flex>
  );
}

export default Intro;
