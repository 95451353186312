import {callGet, callPost} from "../../lib/api-private";
import {GetUserResponse} from "../../generated-client/model/get-user-response";
import {SendOtpRequest} from "../../generated-client/model/send-otp-request";
import {VerifyOtpRequest} from "../../generated-client/model/verify-otp-request";
import {VerifyOtpResponse} from "../../generated-client/model/verify-otp-response";

export const sendOtp = async (data: SendOtpRequest) : Promise<void> => {
  await callPost("/auth/otp/send", data);
};

export const verifyCode = async (data: VerifyOtpRequest) : Promise<VerifyOtpResponse> => {
  const response = await callPost("/auth/otp/verify", data);
    return response;
};
