import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Radix';
      src: url('fonts/RadixBold.woff2') format('woff2');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Radix';
      src: url('fonts/RadixBlack.woff2') format('woff2');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Radix';
      src: url('fonts/RadixMedium.woff2') format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Radix';
      src: url('fonts/RadixRegular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Radix';
      src: url('fonts/RadixThin.woff2') format('woff2');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Radix';
      src: url('fonts/RadixLight.woff2') format('woff2');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Radix Hair';
      src: url('fonts/RadixHair.woff2') format('woff2');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
  }
  
   `}
  />
);

export default Fonts;
